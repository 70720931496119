
import './App.scss';
// Components



import {
  // BrowserRouter as Router,
  Route,
  Routes,
  HashRouter as Router
} from "react-router-dom";
import AssetsTable1 from './components/AssetsTable1';
import Landingpage from './landingpage';
// import Layout from './components/Layout';


function App() {


  return (
    <>
        <Router>
      <Routes>
      <Route path='/table' element={<AssetsTable1 />}  />
      <Route path='*' element={<Landingpage />}  />
      </Routes>
    </Router>

    </>
  );
}

export default App;
