import React from 'react'

// ICON
import { BsCurrencyDollar } from 'react-icons/bs'
import { BiUser } from 'react-icons/bi'
import FintechTestimonialsLogo from '../assets/images/main/aboutus/FintechAboutUsElement2.webp'


const Services = () => {
    const [data, setData] = React.useState({totalUsers: 0, totalStakedValue: 0 });

    const fetchData = async () => {
        const res = await fetch(`${process.env.REACT_APP_SERVER_URL}api/chain/getStakedAmountAndUsers`, {
            method: "GET",
            headers: {
                'Content-Type': 'application/json'
            }
        })
        const data = await res.json();
        if (res.ok) {
            setData(data.data)
        } else {
            console.log("Failed to fetch");
        }
    }

    React.useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className="elementor-section elementor-top-section elementor-element elementor-element-397a2df elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="397a2df" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-8184e8a animated-slow elementor-invisible" data-id="8184e8a" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:200}">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-f5f4ab3 elementor-widget elementor-widget-heading" data-id="f5f4ab3" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h5 className="elementor-heading-title elementor-size-default section-name">Services</h5> 
                                </div>
                        </div>
                        <div className="elementor-element elementor-element-d764914 elementor-widget elementor-widget-heading" data-id="d764914" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">Ensuring maximum return on investment through the least possible risk</h2> </div>
                        </div>
                        <div className="elementor-element elementor-element-8ef75b5 elementor-widget elementor-widget-text-editor" data-id="8ef75b5" data-element_type="widget" data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                                <p>At Ethical Node all of our investment and staking options are 100% reliable, smooth, beginner friendly, and ethically sound in accordance with Islamic Guidelines.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-efa67d1" data-id="efa67d1" data-element_type="column" style={{ flexDirection: "column", justifyContent: "space-evenly", position: 'relative', rowGap:"20px", marginBottom:"40px" }}>
                    <div className="elementor-widget-container" style={{ background: "#12122c", alignSelf: "start", width: "80%", borderRadius: "10px", paddingTop: "10px" }}>
                        <div className="bdt-ep-advanced-icon-box" >
                            <div className="bdt-ep-advanced-icon-box-icon mouseover" style={{ marginBottom: "10px" }}>
                                <span className="mouseover center bgshade orange-color">
                                    <BiUser style={{ transform: `scale(1.1)` }} />

                                </span>
                            </div>
                            <div className="bdt-ep-advanced-icon-box-content">
                                <h3 className="bdt-ep-advanced-icon-box-title">
                                    <span style={{ fontWeight: `800` }}>
                                        {data.totalUsers}				</span>
                                </h3>
                                <div className="bdt-ep-advanced-icon-box-description">
                                    <p>Total Users</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="elementor-widget-container" style={{ background: "#12122c", alignSelf: "start", width: "80%", borderRadius: "10px", paddingTop: "10px", marginLeft: "auto" }}>
                        <div className="bdt-ep-advanced-icon-box" >
                            <div className="bdt-ep-advanced-icon-box-icon mouseover" style={{ marginBottom: "10px" }}>
                                <span className="mouseover bgshade center orange-color">
                                    <BsCurrencyDollar style={{ transform: `scale(1.1)` }} />

                                </span>
                            </div>
                            <div className="bdt-ep-advanced-icon-box-content">
                                <h3 className="bdt-ep-advanced-icon-box-title">
                                    <span style={{ fontWeight: `800`, }}>
                                    {"$ " + Number(data.totalStakedValue).toFixed(0)}				</span>
                                </h3>
                                <div className="bdt-ep-advanced-icon-box-description">
                                    <p>Total Amount Staked</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <img src={FintechTestimonialsLogo} alt="" style={{ position: "absolute", right: "-30px", zIndex: "-1" }} className='hidden'/>
                    <img src={FintechTestimonialsLogo} alt="" style={{ position: "absolute", left: "-31px", zIndex: "-1", transform: "rotate(180deg)" }} className='hidden'/>
                </div>
            </div>
        </div>
    )
}

export default Services