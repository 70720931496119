import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Pagination, Navigation } from 'swiper/modules';
// other imports
import Card from './Card';
//testimonial images
import user from '../assets/images/user.png';

const Slider = () => {
    const data = [
        {
            name:`Islamdin`,
            msg:`to be honest, looking at the growth of my investments, although the investments are not big, my soul rejoices 😅`,
            img:"",
            role:""
        },
        {
            name:`Lukman`,
            msg:`Here, investors mainly have a "invest and forget for a couple of years" strategy.
            At short/medium distances there will be ups and downs, and often. And in the long run, projects tied to real life can bring real benefits.`,
            img:"",
            role:""
        },
        {
            name:`Zalim`,
            msg:`Yesterday it was 92%, I thought it is some kind of a mistake but no ☺
            Today it is already 86%, it has fallen a bit!`,
            img:"",
            role:""
        },
        {
            name:`lIlya`,
            msg:`Since crypto is a super volatile thing, I have about 60% growth last month.
            Yesterday it was a little more, but the market cooled down a little overnight😂
            I also had a third deposit in the red. Then he changed his mind and began to grow...`,
            img:"",
            role:""
        },
        {
            name:`Ayrat`,
            msg:`My investment is now in the red (-25%), so I understand that guarantees can be given only by skamers, but here it is the reall staking platform, so i'm waiting in a long run`,
            img:"",
            role:""
        },
    ]
    return (
        <Swiper
            slidesPerView={3}
            spaceBetween={35}
            pagination={{
                clickable: true,
            }}
            navigation={{
                clickable: true,
            }}
            breakpoints={{
                320: {
                    slidesPerView: 1,
                },
                640: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                },
                960: {
                    slidesPerView: 3,
                    slidesPerGroup: 1
                },
                1280: {
                    slidesPerView: 3,
                    slidesPerGroup: 1

                },
                1750: {
                    slidesPerView: 3,
                    slidesPerGroup: 1

                }
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
        >
            {
                data && data.map((item,index)=>{
                    return(
                        <SwiperSlide key={index}>
                            <Card name={item.name} msg={item.msg.length > 150 ? item.msg.slice(0,150) + '...' : item.msg} img={item.img === "" ? user : item.img}/>
                        </SwiperSlide>
                    )
                })
            }
        </Swiper>
    )
}

export default Slider