import React from 'react';
import logo from '../assets/images/main/logo.png';
import { HiShieldCheck } from 'react-icons/hi'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Header = () => {
    const notifySuccess = () =>
        toast.info("Coming Soon", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    const [showPopup, setShowPopup] = React.useState<boolean>(false);
    const onLoginClick = () => {
        let url = process.env.REACT_APP_REDIRECT_URL;
        console.log(url);
        if (url === "") {
            notifySuccess()
        } else {
            debugger
            window.location.href = url || "";
        }
    };
    const onClose = () => {
        setShowPopup(false); // Function to close the custom popup
    };
    return (
        <div id="wrapper-navbar" itemScope itemType="#" className="uicore uicore-navbar elementor-section uicore-h-classic uicore-sticky ui-smart-sticky uicore-transparent " style={{zIndex:1000}}>
            <div className="uicore-header-wrapper">
                <nav className="uicore elementor-container">
                    <div className="uicore-branding">
                        <a href="/" rel="home"> <img className="uicore uicore-logo uicore-main" src={logo} alt="Crypto" /> <img className="uicore uicore-logo uicore-second" src={logo} alt="Crypto" /> <img className="uicore uicore-logo uicore-mobile-main" src={logo} alt="Crypto" /> <img className="uicore uicore-logo uicore-mobile-second" src={logo} alt="Crypto" /> </a>
                    </div>
                    <div className='uicore-nav-menu'>
                        <div className="uicore-menu-container uicore-nav">
                            {/* <ul className="uicore-menu">
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-120"><a href="/"><span className="ui-menu-item-wrapper">Home</span></a></li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-118"><a href="/portfolio"><span className="ui-menu-item-wrapper">Portfolio</span></a></li>
                        <li className="menu-item menu-item-type-custom menu-item-object-custom menu-item-119"><a href="/swap"><span className="ui-menu-item-wrapper">Swap</span></a></li>
                      </ul> */}
                        </div>
                        <div className="uicore uicore-extra">
                            <div className="uicore-custom-area ">
                                <div >
                                    <p style={{marginTop:`15px`}} ><span onClick={onLoginClick} style={{cursor:`pointer`}}  className="uicore-icon-wrapp">
                                        <HiShieldCheck style={{ "fontSize": "25px", color: "var( --e-global-color-uicore_primary )", marginLeft: "20px", marginRight: "10px" }} />
                                        Secure login</span></p>
                                </div>
                            </div>
                            <div className="uicore-cta-wrapper">
                                <button 
                                    style={{ borderRadius: '7px',fontFamily: "Inter SansSerif",fontSize: '14px',fontWeight: '500', lineHeight: "1em",letterSpacing: "0em",color: "#FFF",backgroundColor: "var(--e-global-color-uicore_primary)",borderStyle: "none", padding: "14px 24px",alignSelf: "center"}} 
                                    className="uicore-btn " 
                                    onClick={onLoginClick}
                                >
                                    <span className="elementor-button-text">
                                        Open App
                                    </span> 
                                </button>
                            </div>
                        </div>
                    </div>
                    <button type="button" className="uicore-toggle uicore-ham"> <span className="bars">
                        <span className="bar"></span> <span className="bar"></span> <span className="bar"></span> </span>
                    </button>
                </nav>
            </div>

            {/* Mobile Menu */}
            <div className="uicore-navigation-wrapper uicore-navbar elementor-section elementor-section-boxed uicore-mobile-menu-wrapper" style={{zIndex:500}}>
                <nav className="uicore elementor-container">
                    <div className="uicore-branding uicore-mobile">
                        <a href="/" rel="home"> <img className="uicore uicore-logo" src={logo} alt="Crypto" /> </a>
                    </div>
                    <div className="uicore-branding uicore-desktop"> </div>
                    <button type="button" className="uicore-toggle uicore-ham"> <span className="bars">
                        <span className="bar"></span> <span className="bar"></span> <span className="bar"></span> </span>
                    </button>
                </nav>
                <div className="uicore-navigation-content">
                    <div className="uicore uicore-extra" >
                        <div className="uicore-cta-wrapper" onClick={onLoginClick} style={{cursor:`pointer`}}>
                            <div  onClick={onLoginClick} className="uicore-btn " style={{background:`#E946DA` , padding:`20px 20px`,borderRadius:`8px`}}> <span className="elementor-button-text">Login</span></div>
                        </div>
                        <div className="uicore-cta-wrapper" onClick={onLoginClick} style={{cursor:`pointer`}}>
                            <div  onClick={onLoginClick} className="uicore-btn " style={{background:`#E946DA` , padding:`20px 20px`,borderRadius:`8px`}}> <span className="elementor-button-text">Register</span></div>
                        </div>
                    </div>
                </div>
            </div>
            {/* // POPUP */}
            {
                showPopup &&
                <section className='Popup'>
                    <section className='coming_soon'>
                        <button className='btn' onClick={onClose}>X</button>
                        <h3>Coming Soon</h3>
                    </section>
                </section>
            }
            <ToastContainer />
        </div >


    )
}

export default Header