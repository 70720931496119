//Images
import FintechAboutUsElement1 from '../assets/images/main/aboutus/FintechAboutUsElement1.webp'
import FintechAboutUsElement2 from '../assets/images/main/aboutus/FintechAboutUsElement2.webp'
import FintechAboutUsElement3 from '../assets/images/main/aboutus/FintechAboutUsElement3.webp'
import FintechAboutUsImage from '../assets/images/main/aboutus/FintechAboutUsImage.webp'
import FintechAboutUsImage_300x200 from '../assets/images/main/aboutus/FintechAboutUsImage_300x200.webp'
import FintechAboutUsImage_768x511 from '../assets/images/main/aboutus/FintechAboutUsImage_768x511.webp'
import FintechAboutUsImage_650x433 from '../assets/images/main/aboutus/FintechAboutUsImage_650x433.webp'
import FintechTestimonialsLogo from '../assets/images/main/testimonial/FintechTestimonialsLogo.webp'

// Icons
import {
    TfiLock, TfiLayers, TfiFlagAlt,
} from 'react-icons/tfi'


const About = () => {
    return (
        <div id='about' className="elementor-section elementor-top-section elementor-element elementor-element-4d23c5b elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="4d23c5b" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-3f4ef6f animated-slow elementor-invisible" data-id="3f4ef6f" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;}">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-81ed356 elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-image" data-id="81ed356" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                            <div className="elementor-widget-container"> <img decoding="async" width="173" height="194" src={FintechTestimonialsLogo} className="attachment-full size-full wp-image-18" alt="" loading="lazy" /> </div>
                        </div>
                        <div className="elementor-element elementor-element-10a1f5a elementor-position-left elementor-widget__width-initial elementor-absolute elementor-widget-mobile__width-initial bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-widget elementor-widget-bdt-advanced-icon-box min-width-90" data-id="10a1f5a" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="bdt-ep-advanced-icon-box text-center">
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                        <span className="mouseover red-color">
                                            <TfiLock />
                                        </span>
                                    </div>
                                    <div className="bdt-ep-advanced-icon-box-content">
                                        <h4 className="bdt-ep-advanced-icon-box-title">
                                            <span >
                                                Security First				</span>
                                        </h4>
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p>Dual Authentication Through email and Wallet</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-75739bd elementor-position-left elementor-widget__width-initial elementor-absolute elementor-widget-mobile__width-initial bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-widget elementor-widget-bdt-advanced-icon-box min-width-90" data-id="75739bd" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="bdt-ep-advanced-icon-box text-center">
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                        <span className="mouseover yellow-color">
                                            <TfiLayers />
                                        </span>
                                    </div>
                                    <div className="bdt-ep-advanced-icon-box-content">
                                        <h4 className="bdt-ep-advanced-icon-box-title">
                                            <span >
                                                Simple and Easy				</span>
                                        </h4>
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p>One platform for all your staking needs</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-3b5b348 elementor-position-left elementor-widget__width-initial elementor-absolute elementor-widget-mobile__width-initial bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-widget elementor-widget-bdt-advanced-icon-box min-width-90" data-id="3b5b348" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="bdt-ep-advanced-icon-box text-center">
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                        <span className="mouseover purple-color ">
                                            <TfiFlagAlt />
                                        </span>
                                    </div>
                                    <div className="bdt-ep-advanced-icon-box-content">
                                        <h4 className="bdt-ep-advanced-icon-box-title">
                                            <span >
                                            Seamless Transaction				</span>
                                        </h4>
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p>Stake in any blockchain without running a node.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-49b114a elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-image" data-id="49b114a" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                            <div className="elementor-widget-container"> <img decoding="async" width="173" height="194" src={FintechTestimonialsLogo} className="attachment-full size-full wp-image-18" alt="" loading="lazy" /> </div>
                        </div>
                        <div className="elementor-element elementor-element-ae39f18 elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-image" data-id="ae39f18" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                            <div className="elementor-widget-container"> <img decoding="async" width="276" height="574" src={FintechAboutUsElement1} className="attachment-full size-full wp-image-28" alt="" loading="lazy" /> </div>
                        </div>
                        <div className="elementor-element elementor-element-342a78b elementor-widget__width-auto elementor-absolute elementor-widget elementor-widget-image" data-id="342a78b" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                            <div className="elementor-widget-container"> <img decoding="async" width="163" height="372" src={FintechAboutUsElement2} className="attachment-full size-full wp-image-29" alt="" loading="lazy" /> </div>
                        </div>
                        <div className="elementor-element elementor-element-1d2ff76 elementor-widget__width-auto elementor-absolute elementor-hidden-mobile elementor-widget elementor-widget-image" data-id="1d2ff76" data-element_type="widget" data-settings="{&quot;_position&quot;:&quot;absolute&quot;}" data-widget_type="image.default">
                            <div className="elementor-widget-container"> <img decoding="async" width="179" height="276" src={FintechAboutUsElement3} className="attachment-full size-full wp-image-30" alt="" loading="lazy" /> </div>
                        </div>
                        <div className="elementor-element elementor-element-fd2e360 elementor-widget elementor-widget-image" data-id="fd2e360" data-element_type="widget" data-widget_type="image.default">
                            <div className="elementor-widget-container"> <img decoding="async" width="994" height="662" src={FintechAboutUsImage} className="attachment-full size-full wp-image-11" alt="" loading="lazy" srcSet={`${FintechAboutUsImage} 994w, ${FintechAboutUsImage_300x200} 300w, ${FintechAboutUsImage_768x511} 768w, ${FintechAboutUsImage_650x433} 650w`} sizes="(max-width: 994px) 100vw, 994px" /> </div>
                        </div>
                    </div>
                </div>
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-cc59257 animated-slow elementor-invisible" data-id="cc59257" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:200}">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-86c522e elementor-widget elementor-widget-heading" data-id="86c522e" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h5 className="elementor-heading-title elementor-size-default section-name">About us</h5> </div>
                        </div>
                        <div className="elementor-element elementor-element-0fe148e elementor-widget elementor-widget-heading" data-id="0fe148e" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">Transparent, Reliable, and Ethical Crypto Investments</h2> </div>
                        </div>
                        <div className="elementor-element elementor-element-3ebc779 elementor-widget elementor-widget-text-editor" data-id="3ebc779" data-element_type="widget" data-widget_type="text-editor.default">
                            <div className="elementor-widget-container"> </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About