import React from "react";
import ScrollTop from './ScrollTop';
import Header from './Header';
import Footer from './Footer';
const Layout = ({ children }: { children: React.ReactNode }) => {
    return (
        <section className="home page-template-default page page-id-34 wp-embed-responsive non-logged-in ui-a-dsmm-slide  elementor-default elementor-kit-7 elementor-page elementor-page-34">
            <div className="uicore-body-content">
                <div id="uicore-page">
                    <Header />
                    <div id="content" className="uicore-content">
                        <div id="primary" className="content-area">
                            <article id="post-34" className="post-34 page type-page status-publish hentry">
                                <main className="entry-content">
                                    <div data-elementor-type="wp-page" data-elementor-id="34" className="elementor elementor-34">

                                        {children}
                                    </div>
                                </main>
                            </article>
                        </div>
                    </div>
                    <Footer />
                </div>
                <ScrollTop />
            </div>
        </section>
    )
};

export default Layout;
