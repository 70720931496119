import React from 'react'
import AssetsTable from './components/AssetsTable';
import AssetsTable1 from './components/AssetsTable1';
import AssetsTable2 from './components/AssetsTable2';
import NewsLetter from './components/NewsLetter';
import Testimonial from './components/Testimonial';
import About from './components/About';
import HeroSection from './components/HeroSection';
import Services from './components/Services';
import Product from './components/Product';
import Layout from './components/Layout';

const Landingpage = () => {
  return (
    <>
        <Layout>
      <HeroSection />

      <AssetsTable2 />

      <About />

      <Services />

      <Product />

      <Testimonial />

      <NewsLetter />
      </Layout>
      </>
  )
}

export default Landingpage