import React from 'react'
import logo from '../assets/images/main/logo.png';
import logo_300x165 from '../assets/images/main/logo_300x165.png'

import { FaFacebookSquare, FaTwitterSquare,  FaTelegram } from 'react-icons/fa'


const Footer = () => {
    const [socials, setSocials] = React.useState({facebook:'', twitter:'', contactUs:'', address:'', email:'', telegram:''});
    
    React.useEffect(() => {
        const fetchData = async () => {
            const res = await fetch(`${process.env.REACT_APP_SERVER_URL}api/admin/getHelpSettings`, {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            const data = await res.json();
            if (res.ok) {
                setSocials(data.data)
            } else {
                console.log("Failed to fetch");
            }
        }

        fetchData()
    }, [])

    return (
        <footer className="uicore-footer-wrapper">
            <div className="uicore uicore-inner-footer elementor-section elementor-section-boxed ">
                <div className="uicore elementor-container three_left ">
                    <div className="uicore-footer-column uicore-animate">
                        <div className="uicore-footer-widget">
                            <figure className="wp-block-image size-full is-resized"><img decoding="async" loading="lazy" src={logo} alt="" className="wp-image-125" width="177" height="96" srcSet={`${logo} 600w, ${logo_300x165} 300w`} sizes="(max-width: 177px) 100vw, 177px" />
                                <figcaption className="wp-element-caption"></figcaption>
                            </figure>
                            <p style={{textAlign:`justify`}}>We have developed this protocol with the aim of providing a comprehensive solution for Shariah screening and proof-of-stake services, catering to the needs of Muslim worldwide. Our goal is to ensure a seamless, dependable, and secure process, enhancing comfort and reliability.</p>
                        </div>
                        <div className="uicore-footer-widget">
                            <div className="is-layout-flow wp-block-group">
                                <div className="wp-block-group__inner-container"></div>
                            </div>
                        </div>
                    </div>
                    <div className="uicore-footer-column uicore-animate">
                    </div>
                    <div className="uicore-footer-column uicore-animate">
                        <div className="uicore-footer-widget">
                            <div className="is-layout-flow wp-block-group">
                                <div className="wp-block-group__inner-container">
                                    <h4 id="contact-us">Contact Us</h4>
                                    <p>{socials.address}</p>
                                    <p>{socials.email}</p>
                                    <p>{socials.contactUs}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="uicore uicore-copyrights elementor-section elementor-section-boxed ">
                <div className="uicore elementor-container">
                    <div className="uicore-copyrights-wrapper">
                        <div className="uicore-copy-content uicore-animate ">
                            <p className="p1"><span className="s1">© 2023 All Rights Reserved</span></p>
                        </div>
                        <div className="uicore-copy-socials uicore-animate">
                            <a className="uicore-social-icon uicore-link" href={socials?.facebook} aria-label="Facebook"><FaFacebookSquare /></a>
                            <a className="uicore-social-icon uicore-link " href={socials?.twitter} aria-label="Tweeter"><FaTwitterSquare /></a>
                            <a className="uicore-social-icon uicore-link " href={socials?.telegram} aria-label="Instagram"><FaTelegram /></a>
                            {/* <a className="uicore-social-icon uicore-link" href="#linkedin" target="_blank" aria-label="LinkedIn"><FaLinkedin /></a> */}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer