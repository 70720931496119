// Hero section images
import KeyFeatures from './KeyFeatures'


// import { BsPlayCircle } from 'react-icons/bs'


const HeroSection = () => {
    return (
        <div className="elementor-section elementor-top-section elementor-element elementor-element-862ed30 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="862ed30" data-element_type="section" data-settings="{&quot;background_background&quot;:&quot;classic&quot;}" style={{padding:"150px 0px 10px 0px",zIndex:1}}>
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-66baaa5 animated-slow elementor-invisible" data-id="66baaa5" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:200}" style={{width:'140%'}}>
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-8a3f9a5 elementor-widget elementor-widget-heading" data-id="8a3f9a5" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container" style={{padding:"0px"}}>
                                <h1 className="elementor-heading-title  hero-title"><span  style={{letterSpacing:`0.1mm`}}>EthicalNode provides</span> <br/> a suite of user-friendly applications for <br/>partner networks</h1>
                                <p style={{fontSize:`1.1rem`,fontWeight:`300`,letterSpacing:`0.1mm`}}> We help secure as an industry-leading validator node operator and investor</p> </div>
                        </div>
                        <div className="elementor-element elementor-element-e4aed35 elementor-widget__width-auto elementor-widget elementor-widget-button" data-id="e4aed35" data-element_type="widget" data-widget_type="button.default">
                            <div className="elementor-widget-container">
                                <div className="elementor-button-wrapper">
                                    <a href='#about' className="elementor-button-link elementor-button elementor-size-sm" > <span className="elementor-button-content-wrapper">
                                        <span className="elementor-button-text">Learn More</span> </span>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-294e1d8 elementor-widget__width-auto elementor-widget elementor-widget-lightbox" data-id="294e1d8" data-element_type="widget" data-widget_type="lightbox.default">
                            <div className="elementor-widget-container">
                                <div id="bdt-lightbox-294e1d8" className="bdt-lightbox-wrapper">
                                    <div data-bdt-lightbox=" animation: slide; video-autoplay: true;">
                                        {/* <button data-elementor-open-lightbox="no" className="elementor-button elementor-size-md"> <span className="elementor-button-content-wrapper">
                                            <span className="elementor-button-icon elementor-align-icon-left">

                                                <BsPlayCircle />
                                            </span> <span className="elementor-button-text">Watch Video</span> </span>
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <KeyFeatures/>
            </div>
        </div>
    )
}

export default HeroSection