import {
    TfiWallet,TfiLayers,
    TfiBookmark, TfiServer, TfiThought, TfiLink, TfiViewGrid, TfiHeart
} from 'react-icons/tfi'

const Product = () => {
    return (
        <div className="elementor-section elementor-top-section elementor-element elementor-element-46fb646 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="46fb646" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-e151976" data-id="e151976" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-section elementor-inner-section elementor-element elementor-element-d1a3fc7 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="d1a3fc7" data-element_type="section">
                            <div className="elementor-container elementor-column-gap-default">
                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-82d08b9" data-id="82d08b9" data-element_type="column">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-1a15e30 elementor-position-left animated-slow bdt-global-link-yes bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="1a15e30" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                                            <div className="elementor-widget-container">
                                                <div className="bdt-ep-advanced-icon-box" >
                                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                                        <span className="mouseover bgtransparent orange-color">
                                                            <TfiBookmark style={{transform:`scale(1.1)`}} />

                                                        </span>
                                                    </div>
                                                    <div className="bdt-ep-advanced-icon-box-content">
                                                        <h3 className="bdt-ep-advanced-icon-box-title">
                                                            <span style={{fontWeight:`700`}}>
                                                                Crypto Staking				</span>
                                                        </h3>
                                                        <div className="bdt-ep-advanced-icon-box-description">
                                                            <p>Trade and monitor accounts</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-7eecf5c elementor-position-left animated-slow bdt-global-link-yes bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="7eecf5c" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                                            <div className="elementor-widget-container">
                                                <div className="bdt-ep-advanced-icon-box" >
                                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                                        <span className="mouseover bgtransparent blue-color">
                                                            <TfiServer style={{transform:`scale(1.1)`}}/>
                                                        </span>
                                                    </div>
                                                    <div className="bdt-ep-advanced-icon-box-content">
                                                        <h3 className="bdt-ep-advanced-icon-box-title">
                                                            <span style={{fontWeight:`700`}}>
                                                                Maximized Security				</span>
                                                        </h3>
                                                        <div className="bdt-ep-advanced-icon-box-description">
                                                            <p>Blockchain-based finance<span
                                                            // style={{ color: "var( --e-global-color-uicore_headline )", backgroundColor: "transparent", fontFamily: "var(--uicore-typography--p-f,&quot;Inter&quot;)", fontSize: "var(--uicore-typography--p-s,&quot;10px&quot;)", fontStyle: "var(--uicore-typography--p-st,&quot;normal&quot;)", fontWeight: "var(--uicore-typography--p-w,&quot;600&quot;)", letterSpacing: "var(--uicore-typography--p-ls,&quot;-0.027em&quot;)", textTransform: "var(--uicore-typography--p-t,&quot;none&quot;)" }}
                                                            ></span></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="elementor-element elementor-element-810c35c elementor-position-left animated-slow bdt-global-link-yes bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="810c35c" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                                            <div className="elementor-widget-container">
                                                <div className="bdt-ep-advanced-icon-box" >
                                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                                        <span className="mouseover bgtransparent purple-color">
                                                            <TfiThought style={{transform:`scale(1.1)`}}/>
                                                        </span>
                                                    </div>
                                                    <div className="bdt-ep-advanced-icon-box-content">
                                                        <h3 className="bdt-ep-advanced-icon-box-title">
                                                            <span style={{fontWeight:`700`}}>
                                                                Swapping Crypto				</span>
                                                        </h3>
                                                        <div className="bdt-ep-advanced-icon-box-description">
                                                            <div>Unique identity and ownership</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="elementor-column elementor-col-50 elementor-inner-column elementor-element elementor-element-bfa4f75 animated-slow elementor-invisible" data-id="bfa4f75" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:200}">
                                    <div className="elementor-widget-wrap elementor-element-populated">
                                        <div className="elementor-element elementor-element-99a2bae elementor-widget elementor-widget-heading" data-id="99a2bae" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h5 className="elementor-heading-title elementor-size-default section-name">Product</h5> </div>
                                        </div>
                                        <div className="elementor-element elementor-element-bf06913 elementor-widget elementor-widget-heading" data-id="bf06913" data-element_type="widget" data-widget_type="heading.default">
                                            <div className="elementor-widget-container">
                                                <h2 className="elementor-heading-title elementor-size-default">We Provide Comprehensive Cryptocurrency Solutions</h2> </div>
                                        </div>
                                        <div className="elementor-element elementor-element-104ce86 elementor-widget elementor-widget-text-editor" data-id="104ce86" data-element_type="widget" data-widget_type="text-editor.default">
                                            <div className="elementor-widget-container">
                                                <p><span
                                                    style={{ color: "#999999", fontFamily: "ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'", fontSize: "18px" }}
                                                >With ethicalnode.com you can invest your funds in cryptocurrencies completely in accordance with Shariah and ethical standards</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-c12441d elementor-position-left elementor-widget__width-initial elementor-widget-tablet__width-inherit animated-slow bdt-global-link-yes bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="c12441d" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="bdt-ep-advanced-icon-box" >
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                        <span className="mouseover bgtransparent yellow-color">
                                            <TfiWallet style={{transform:`scale(1.1)`}}/>
                                        </span>
                                    </div>
                                    <div className="bdt-ep-advanced-icon-box-content">
                                        <h3 className="bdt-ep-advanced-icon-box-title">
                                            <span style={{fontWeight:`700`}}>
                                                Wallet Integration				</span>
                                        </h3>
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p>Built on blockchain network<span
                                            // style={{ color: "var(--uicore-typography--p-c,&quot;#070707&quot;)", fontFamily: "var(--uicore-typography--p-f,&quot;Inter&quot;)", fontSize: "var(--uicore-typography--p-s,&quot;10px&quot;)", fontStyle: "var(--uicore-typography--p-st,&quot;normal&quot;)", fontWeight: "var(--uicore-typography--p-w,&quot;600&quot;)", letterSpacing: "var(--uicore-typography--p-ls,&quot;-0.027em&quot;)", textTransform: "var(--uicore-typography--p-t,&quot;none&quot;)" }}
                                            ></span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-5f52f6b elementor-position-left elementor-widget__width-initial elementor-widget-tablet__width-inherit animated-slow bdt-global-link-yes bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="5f52f6b" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="bdt-ep-advanced-icon-box" >
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                        <span className="mouseover bgtransparent green-color">
                                            <TfiLink style={{transform:`scale(1.1)`}}/>
                                        </span>
                                    </div>
                                    <div className="bdt-ep-advanced-icon-box-content">
                                        <h3 className="bdt-ep-advanced-icon-box-title">
                                            <span style={{fontWeight:`700`}}>
                                                Easy Linking				</span>
                                        </h3>
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p>Digital investment data storage<span
                                            // style={{ color: "var(--uicore-typography--p-c,&quot;#070707&quot;)", fontFamily: "var(--uicore-typography--p-f,&quot;Inter&quot;)", fontSize: "var(--uicore-typography--p-s,&quot;10px&quot;)", fontStyle: "var(--uicore-typography--p-st,&quot;normal&quot;)", fontWeight: "var(--uicore-typography--p-w,&quot;600&quot;)", letterSpacing: "var(--uicore-typography--p-ls,&quot;-0.027em&quot;)", textTransform: "var(--uicore-typography--p-t,&quot;none&quot;)" }}
                                            ></span></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-3ba8c05 elementor-position-left elementor-widget__width-initial elementor-widget-tablet__width-inherit animated-slow bdt-global-link-yes bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="3ba8c05" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="bdt-ep-advanced-icon-box" >
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                        <span className="mouseover bgtransparent pink-color">
                                            <TfiLayers style={{transform:`scale(1.1)`}}/>
                                        </span>
                                    </div>
                                    <div className="bdt-ep-advanced-icon-box-content">
                                        <h3 className="bdt-ep-advanced-icon-box-title">
                                            <span style={{fontWeight:`700`}}>
                                                Beginner Friendly Interface				</span>
                                        </h3>
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p>Easy financial transactions</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-bc8b490 elementor-position-left elementor-widget__width-initial elementor-widget-tablet__width-inherit animated-slow bdt-global-link-yes bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="bc8b490" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="bdt-ep-advanced-icon-box" >
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                        <span className="mouseover bgtransparent red-color">
                                            <TfiViewGrid style={{transform:`scale(1.1)`}}/>
                                        </span>
                                    </div>
                                    <div className="bdt-ep-advanced-icon-box-content">
                                        <h3 className="bdt-ep-advanced-icon-box-title">
                                            <span style={{fontWeight:`700`}}>
                                                Shariah Compliant Currencies				</span>
                                        </h3>
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p>Processing &amp; analyzing data sets</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-2477190 elementor-position-left elementor-widget__width-initial elementor-widget-tablet__width-inherit animated-slow bdt-global-link-yes bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="2477190" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container">
                                <div className="bdt-ep-advanced-icon-box" >
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover">
                                        <span className="mouseover bgtransparent orange-color">
                                            <TfiHeart style={{transform:`scale(1.1)`}}/>
                                        </span>
                                    </div>
                                    <div className="bdt-ep-advanced-icon-box-content">
                                        <h3 className="bdt-ep-advanced-icon-box-title">
                                            <span style={{fontWeight:`700`}}>
                                                Maximized Return on Investment				</span>
                                        </h3>
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p>Best digital tokens</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product