import React from 'react'
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Testimonial section images
import FintechTestimonialsLogo from '../assets/images/main/testimonial/FintechTestimonialsLogo.webp'
const NewsLetter = () => {
    const notifySuccess = () =>
        toast.success("Welcome to our community", {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

    const [loader, setLoader] = React.useState(false)

    const [email, setEmail] = React.useState("")

    // Define an interface representing the response structure
    interface FormResponse {
        result: string;
        row: number;
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setLoader(true)
        if (process.env.REACT_APP_SERVER_URL) {
            const res = await fetch(`${process.env.REACT_APP_SERVER_URL}api/newsletter`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ email: email }),
            })
            if (res.ok) { 
                setLoader(false)
                setEmail('')
                notifySuccess()
            } else {
                console.log("Internal Server Error");
            }
        } else {
            try {
                const formData = new FormData();
                formData.append('email', email);

                const response = await fetch(`${process.env.REACT_APP_SCRIPT_URL}`, { method: 'POST', body: formData });

                if (response.ok) {
                    const responseData: FormResponse = await response.json(); // Explicitly parse the response data
                    setEmail('');
                    notifySuccess();
                    console.log(responseData);
                    
                } else {
                    console.log('Internal Server Error');
                }
            } catch (error: any) {
                console.error('Error!', error.message);
            }

            setLoader(false);
        }
    }


    return (
        <div className="elementor-section elementor-top-section elementor-element elementor-element-0f133da elementor-reverse-tablet elementor-reverse-mobile elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="0f133da" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-7394e75 animated-slow elementor-invisible" data-id="7394e75" data-element_type="column" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;,&quot;animation_delay&quot;:200}">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-bebc523 elementor-widget elementor-widget-heading" data-id="bebc523" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h5 className="elementor-heading-title elementor-size-default section-name">Subscribe</h5> </div>
                        </div>
                        <div className="elementor-element elementor-element-049ef21 elementor-widget elementor-widget-heading" data-id="049ef21" data-element_type="widget" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">Join our mail list</h2> </div>
                        </div>
                        <div className="elementor-element elementor-element-193032c animated-slow elementor-invisible elementor-widget elementor-widget-text-editor" data-id="193032c" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:600}" data-widget_type="text-editor.default">
                            <div className="elementor-widget-container">
                                <p>Subscribe to be the first to hear about upcoming fintech services!</p>
                            </div>
                        </div>
                        <div className="elementor-element elementor-element-0b6b989 elementor-widget elementor-widget-bdt-mailchimp" data-id="0b6b989" data-element_type="widget" data-widget_type="bdt-mailchimp.default">
                            <div className="elementor-widget-container">
                                <div className="bdt-newsletter-wrapper">
                                    <form onSubmit={handleSubmit} className="bdt-mailchimp bdt-grid bdt-flex-middle" style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                        <div className="bdt-newsletter-input-wrapper bdt-width-expand w-70" >
                                            <input type="email" name="email" placeholder="Enter your email address" required className="bdt-input border-none" value={email} onChange={(e) => {
                                                setEmail(e.target.value)
                                            }} />
                                            <input type="hidden" name="action" value="element_pack_mailchimp_subscribe" />
                                            <input type="hidden" name="bdt-sf-bdt-mailchimp-0b6b989" value="true" />  </div>
                                        <div className="bdt-newsletter-signup-wrapper bdt-width-auto text-center">
                                            <button type="submit" className="bdt-newsletter-btn bdt-button bdt-button-primary bdt-width-1-1">
                                                <div className="bdt-newsletter-btn-content-wrapper  ">
                                                    <div className="bdt-newsletter-btn-text bdt-display-inline-block">
                                                        {!loader === true ? "Join Now" : (<div className="loader"></div>)}
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </form>  </div>  </div>
                        </div>
                    </div>
                </div>
                <div className="elementor-column elementor-col-50 elementor-top-column elementor-element elementor-element-79f5fc0 elementor-invisible" data-id="79f5fc0" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeIn&quot;}">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-d934738 elementor-widget elementor-widget-image" data-id="d934738" data-element_type="widget" data-widget_type="image.default">
                            <div style={{ textAlign: 'center' }} className="elementor-widget-container"> <img decoding="async" width="173" height="194" src={FintechTestimonialsLogo} className="attachment-full size-full wp-image-18" alt="" loading="lazy" /> </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default NewsLetter