import Slider from './Slider'
const Testimonial = () => {
    return (
        <div className="elementor-section elementor-top-section elementor-element elementor-element-7947f94 elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="7947f94" data-element_type="section">
            <div className="elementor-container elementor-column-gap-default">
                <div className="elementor-column elementor-col-100 elementor-top-column elementor-element elementor-element-339e396" data-id="339e396" data-element_type="column">
                    <div className="elementor-widget-wrap elementor-element-populated">
                        <div className="elementor-element elementor-element-464e071 elementor-invisible elementor-widget elementor-widget-heading" data-id="464e071" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h5 className="elementor-heading-title elementor-size-default section-name">Testimonials</h5> </div>
                        </div>
                        <div className="elementor-element elementor-element-588e712 animated-slow elementor-invisible elementor-widget elementor-widget-heading" data-id="588e712" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="heading.default">
                            <div className="elementor-widget-container">
                                <h2 className="elementor-heading-title elementor-size-default">What our happy clients say</h2> </div>
                        </div>
                        <div className="elementor-section elementor-inner-section elementor-element elementor-element-a9a4bef elementor-section-boxed elementor-section-height-default elementor-section-height-default" data-id="a9a4bef" data-element_type="section">
                            <Slider/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Testimonial