import { AiFillStar, } from 'react-icons/ai'

interface CardProps {
    img: string;
    msg: string;
    name: string;
    role: string;
  }


const Card: React.FC<CardProps> = ({ img, msg, name, role }) => {
    return (
        <div className="elementor-column elementor-col-33 elementor-inner-column elementor-element elementor-element-ee2a4e3 animated-slow elementor-invisible" data-id="ee2a4e3" data-element_type="column" data-settings="{&quot;background_background&quot;:&quot;classic&quot;,&quot;animation&quot;:&quot;fadeIn&quot;}" style={{ width: "100%", height:"400px" }}>
            <div className="elementor-widget-wrap elementor-element-populated">
                <div className="elementor-element elementor-element-3ba0964 elementor-widget elementor-widget-image" data-id="3ba0964" data-element_type="widget" data-widget_type="image.default">
                    <div className="elementor-widget-container"> <img decoding="async" width="128" height="128" src={img} className="attachment-full size-full wp-image-14" alt="" loading="lazy" /> </div>
                </div>
                <div className="elementor-element elementor-element-def86a5 elementor-icon-list--layout-inline elementor-list-item-link-full_width elementor-widget elementor-widget-icon-list" data-id="def86a5" data-element_type="widget" data-widget_type="icon-list.default">
                    <div className="elementor-widget-container">
                        <ul className="elementor-icon-list-items elementor-inline-items">
                            <li className="elementor-icon-list-item elementor-inline-item"> <span className="elementor-icon-list-icon">
                                <AiFillStar />					</span> <span className="elementor-icon-list-text"></span> </li>
                            <li className="elementor-icon-list-item elementor-inline-item"> <span className="elementor-icon-list-icon">
                                <AiFillStar />							</span> <span className="elementor-icon-list-text"></span> </li>
                            <li className="elementor-icon-list-item elementor-inline-item"> <span className="elementor-icon-list-icon">
                                <AiFillStar />							</span> <span className="elementor-icon-list-text"></span> </li>
                            <li className="elementor-icon-list-item elementor-inline-item"> <span className="elementor-icon-list-icon">
                                <AiFillStar />							</span> <span className="elementor-icon-list-text"></span> </li>
                            <li className="elementor-icon-list-item elementor-inline-item"> <span className="elementor-icon-list-icon">
                                <AiFillStar />							</span> <span className="elementor-icon-list-text"></span> </li>
                        </ul>
                    </div>
                </div>
                <div className="elementor-element elementor-element-57961a8 elementor-widget elementor-widget-text-editor" data-id="57961a8" data-element_type="widget" data-widget_type="text-editor.default">
                    <div className="elementor-widget-container">
                        <p>{msg}</p>
                    </div>
                </div>
                <div className="elementor-element elementor-element-be3000f elementor-widget elementor-widget-heading" data-id="be3000f" data-element_type="widget" data-widget_type="heading.default">
                    <div className="elementor-widget-container">
                        <h3 className="elementor-heading-title elementor-size-default">{name}</h3> </div>
                </div>
                <div className="elementor-element elementor-element-d672887 elementor-widget elementor-widget-heading" data-id="d672887" data-element_type="widget" data-widget_type="heading.default">
                    <div className="elementor-widget-container">
                        <h6 className="elementor-heading-title elementor-size-default">{role}</h6> </div>
                </div>
            </div>
        </div>
    )
}

export default Card