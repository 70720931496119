import {
    TfiAgenda, TfiWallet, TfiCrown,
} from 'react-icons/tfi'

const KeyFeatures = () => {
    return (
        <div className="elementor-section elementor-top-section elementor-element elementor-element-2eff60e animated-slow elementor-section-boxed elementor-section-height-default elementor-section-height-default elementor-invisible" data-id="2eff60e" data-element_type="section" data-settings="{&quot;animation&quot;:&quot;fadeIn&quot;}" style={{ marginTop: "0px", marginBottom: '0px', width: "100%" }}>
            <div className="elementor-container elementor-column-gap-default" style={{ flexDirection: "column", width: "100%" }}>
                <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-fe196a3" data-id="fe196a3" data-element_type="column" style={{ width: "100%" }}>
                    <div className="elementor-widget-wrap elementor-element-populated" style={{ padding: "5px" }}>
                        <div className="elementor-element elementor-element-d8f1634 elementor-position-left animated-slow bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="d8f1634" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container" style={{ padding: "10px 0px 5px 25px" }}>
                                <div className="bdt-ep-advanced-icon-box" style={{ display: "flex", alignItems:"center" }}>
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover" style={{ marginBottom: "0px", marginRight: "20px" }}>
                                        <span className="mouseover bgshade green-color" style={{ margin:`6px 0px` }}>
                                            <TfiAgenda />
                                        </span>
                                    </div>
                                    <h3 className="bdt-ep-advanced-icon-box-title">
                                            <span style={{ fontSize: "20px", fontWeight: `600` }}>
                                                Shariah Compliant				</span>
                                        </h3>
                                    {/* <div className="bdt-ep-advanced-icon-box-content">
                                       
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p><span
                                            style={{fontSize:"14px", marginBottom:"-8px"}}
                                            >Shariah Compliant</span></p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-87ffd6a" data-id="87ffd6a" data-element_type="column" style={{ width: "100%" }}>
                    <div className="elementor-widget-wrap elementor-element-populated" style={{ padding: "5px" }}>
                        <div className="elementor-element elementor-element-5edf9a8 elementor-position-left animated-slow bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="5edf9a8" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:200}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container" style={{ padding: "10px 0px 5px 25px" }}>
                                <div className="bdt-ep-advanced-icon-box" style={{ display: "flex", alignItems:"center" }}>
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover" style={{ marginBottom: "0px", marginRight: "20px" }}>
                                        <span className="mouseover bgshade yellow-color" style={{ margin:`6px 0px` }}>
                                            <TfiWallet />
                                        </span>
                                    </div>
                                    <h3 className="bdt-ep-advanced-icon-box-title">
                                            <span style={{ fontSize: "20px", fontWeight: `600` }}>
                                                Safe and Secure				</span>
                                        </h3>
                                    {/* <div className="bdt-ep-advanced-icon-box-content">
                                        
                                        <div className="bdt-ep-advanced-icon-box-description">
                                            <p><span
                                           style={{fontSize:"14px", marginBottom:"-8px"}}
                                            >Safety and Security</span></p>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="elementor-column elementor-col-33 elementor-top-column elementor-element elementor-element-da1ea14" data-id="da1ea14" data-element_type="column" style={{ width: "100%" }}>
                    <div className="elementor-widget-wrap elementor-element-populated" style={{ padding: "5px" }}>
                        <div className="elementor-element elementor-element-bebb13c elementor-position-left animated-slow bdt-icon-type-icon elementor-vertical-align-top bdt-icon-effect-none elementor-invisible elementor-widget elementor-widget-bdt-advanced-icon-box" data-id="bebb13c" data-element_type="widget" data-settings="{&quot;_animation&quot;:&quot;fadeIn&quot;,&quot;_animation_delay&quot;:400}" data-widget_type="bdt-advanced-icon-box.default">
                            <div className="elementor-widget-container" style={{ padding: "10px 0px 5px 25px" }}>
                                <div className="bdt-ep-advanced-icon-box" style={{ display: "flex", alignItems:"center" }}>
                                    <div className="bdt-ep-advanced-icon-box-icon mouseover" style={{ marginBottom: "0px", marginRight: "20px" }}>
                                        <span className="mouseover bgshade blue-color" style={{ margin:`6px 0px` }}>
                                            <TfiCrown />
                                        </span>
                                    </div>
                                        <h3 className="bdt-ep-advanced-icon-box-title">
                                            <span style={{ fontSize: "20px", fontWeight: `600` }}>
                                                Maximized Return				</span>
                                        </h3>
                                        {/* <div className="bdt-ep-advanced-icon-box-description">
                                            <p><span
                                            style={{fontSize:"14px", marginBottom:"-8px"}}
                                            >Maximized Return</span></p>
                                        </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default KeyFeatures